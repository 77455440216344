import axios from 'axios';

const axiosInstance = axios.create({
    // config
    // withCredentials: true
});

axiosInstance.interceptors.request.use(config => {
    if (
        [
            'post',
            'put',
            'patch',
            'delete',
        ].includes(config.method)
    ) {
        const tokenInput = document.querySelector('meta[name=csrf-token]');
        const tokenNameInput = document.querySelector('meta[name=csrf-param]');
        if (tokenInput && tokenNameInput) {
            const tokenValue = tokenInput.getAttribute('content');
            const tokenName = tokenNameInput.getAttribute('content');
            if (tokenValue && tokenName) {
                config.data = config.data || {};
                config.data[tokenName] = tokenValue;
            }
        }
    }
    return config;
}, error => Promise.reject(error));

const mockApi = (payload = {}) => new Promise((resolve, reject) => {
    // eslint-disable-next-line no-console
    console.error('Warning: You are using $mockApi! Payload:', payload);
    setTimeout(() => {
        if (payload.mockError === true) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ error: true });
        } else if (payload.mockError) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(mockError);
        } else if (payload.mockData) {
            resolve({ data: payload.mockData });
        } else {
            resolve({ ok: true });
        }
    }, payload.mockTime || 200);
});

export default {
    $axios: axiosInstance,
    $handleError: (error, that, prefix = 'errors.', defaultErrorCode = 'default', useConsole = true) => {
        if (error?.response?.data?.error) {
            that.$notification(that.$t(`${prefix}${error.response.data.error}`), 'bad');
        } else {
            that.$notification(that.$t(`${prefix}${defaultErrorCode}`), 'bad');
        }
        if (useConsole) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    },
    $mockApi: payload => mockApi(payload),
    searchResults(payload) {
        const server = process.env.LIVE_ENDPOINT;
        return axiosInstance.get(`${server}/search`, {
            params: payload || {},
        });
    },
    bulkCSVUpload: {
        CSVDownload(payload = {
            siteId: undefined,
            type: undefined,
        }) {
            if (payload.type === 'synonyms') {
                return axiosInstance.get(`/sites/${payload.siteId}/catalog_management/synonyms/bulk_download`, {
                    headers: {
                        accept: 'text/csv',
                    },
                });
            }
            throw new Error('unknown type');
        },
        synonyms(payload = {
            siteId: undefined,
            items: [],
            type: undefined,
        }) {
            // TODO
            return axiosInstance.post(`/sites/${payload.siteId}/catalog_management/synonyms/bulk_upload`, {
                data: {
                    items: payload.items.map(x => {
                        const [first, ...rest] = x.filter(y => y !== '');
                        return [first, rest.join(',')];
                    }),
                    type: payload.type,
                },
            });
        },
        fixit(payload = {
            siteId: undefined,
            items: [],
            type: undefined,
        }) {
            return axiosInstance.post(`/sites/${payload.siteId}/fixit/bulk_upload`, {
                data: {
                    items: payload.items.map(x => {
                        const [first, ...rest] = x.filter(y => y !== '');
                        return [first, rest.join(',')];
                    }),
                    type: payload.type,
                },
            });
        },
    },
    TIC: {
        loadTypes(payload = {
            siteId: undefined,
        }) {
            return axiosInstance.get(`/sites/${payload.siteId}/top_items_customizations/page_json`, {
                params: {},
            });
        },
        getDetail(payload = {
            siteId: undefined,
            queryId: undefined,
            filter: 'all',
        }) {
            return axiosInstance.get(`/sites/${payload.siteId}/top_items_customizations/show_json`, {
                params: {
                    id: payload.queryId,
                    filter: payload.filter,
                },
            });
        },
        createCustomization(payload = {
            siteId: undefined,
            type: undefined,
        }) {
            return axiosInstance.post(`/sites/${payload.siteId}/top_items_customizations/create_json`, {
                type: payload.type,
            });
        },
        saveRules(payload = {
            rules: [],
            queryId: undefined,
            siteId: undefined,
            whereToUse: undefined,
        }) {
            return axiosInstance
                .post(`/sites/${payload.siteId}/top_items_customizations/save_rules_json`, {
                    rules: payload.rules,
                    queryId: payload.queryId,
                    where_to_use: payload.whereToUse,
                });
        },
    },
    QC: {
        // deprecated
        deleteRule(payload = {
            ruleId: undefined,
            siteId: undefined,
        }) {
            return axiosInstance
                .delete(`/sites/${payload.siteId}/query_customizations/delete_rule_json`, {
                    data: {
                        id: payload.ruleId,
                    },
                });
        },

        saveRules(payload = {
            rules: [],
            queryId: undefined,
            siteId: undefined,
            whereToUse: undefined,
        }) {
            return axiosInstance
                .post(`/sites/${payload.siteId}/query_customizations/save_rules_json`, {
                    rules: payload.rules,
                    queryId: payload.queryId,
                    where_to_use: payload.whereToUse,
                });
        },

        getActiveCustomizations(payload = {
            siteId: undefined,
            query: '',
            page: 1,
        }) {
            return axiosInstance.get(`/sites/${payload.siteId}/query_customizations/page_json`, {
                params: {
                    q: payload.query,
                    page: payload.page,
                },
            });
        },

        getDetail(payload = {
            siteId: undefined,
            queryId: undefined,
            filter: 'all',
        }) {
            return axiosInstance.get(`/sites/${payload.siteId}/query_customizations/show_json`, {
                params: {
                    id: payload.queryId,
                    filter: payload.filter,
                },
            });
        },

        createCustomization(payload = {
            siteId: undefined,
            query: undefined,
            type: undefined,
        }) {
            return axiosInstance.post(`/sites/${payload.siteId}/query_customizations/create_json`, {
                query: payload.query,
                type: payload.type,
            });
        },

        deleteCustomization(payload = {
            siteId: undefined,
            queryId: undefined,
        }) {
            return axiosInstance.delete(`/sites/${payload.siteId}/query_customizations/destroy_json`, {
                data: {
                    id: payload.queryId,
                },
            });
        },

        changeMode(payload = {
            queryId: undefined,
            siteId: undefined,
            mode: undefined,
            source: undefined,
        }) {
            return axiosInstance
                .post(`/sites/${payload.siteId}/query_customizations/change_mode`, {
                    queryId: payload.queryId,
                    mode: payload.mode,
                    source: payload.source,
                });
        },
    },
    CL: {
        getActiveCustomizations(payload = {
            siteId: undefined,
            query: '',
            page: 1,
        }) {
            return axiosInstance.get(`/sites/${payload.siteId}/product_listing_customizations/page_json`, {
                params: {
                    q: payload.query,
                    page: payload.page,
                },
            });
        },
        createCustomization(payload = {
            siteId: undefined,
            category: undefined,
            type: undefined,
        }) {
            return axiosInstance.post(`/sites/${payload.siteId}/product_listing_customizations/create_json`, {
                filters: payload.category,
                type: payload.type,
            });
        },
        deleteCustomization(payload = {
            siteId: undefined,
            queryId: undefined,
        }) {
            return axiosInstance.delete(`/sites/${payload.siteId}/product_listing_customizations/destroy_json`, {
                data: {
                    id: payload.queryId,
                },
            });
        },
        getDetail(payload = {
            siteId: undefined,
            queryId: undefined,
            filter: 'all',
        }) {
            return axiosInstance.get(`/sites/${payload.siteId}/product_listing_customizations/show_json`, {
                params: {
                    id: payload.queryId,
                    filter: payload.filter,
                },
            });
        },
        saveRules(payload = {
            rules: [],
            queryId: undefined,
            siteId: undefined,
            whereToUse: undefined,
        }) {
            return axiosInstance
                .post(`/sites/${payload.siteId}/product_listing_customizations/save_rules_json`, {
                    rules: payload.rules,
                    queryId: payload.queryId,
                    where_to_use: payload.whereToUse,
                });
        },
    },
    VS: {
        enableVoiceSearch(payload = {
            siteTrackingCode: undefined,
        }) {
            return axiosInstance.patch(`/sites/${payload.siteTrackingCode}/setup/voice_search/enable`, {
                data: payload,
                has_managed_voice_search: payload.hasManagedVoiceSearch,
            });
        },
        loadData(payload = { siteTrackingCode: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteTrackingCode}/setup/voice_search/show_json`);
        },
        disableVoiceSearch(payload = {
            siteTrackingCode: undefined,
        }) {
            return axiosInstance.patch(`/sites/${payload.siteTrackingCode}/setup/voice_search/disable`, {
                data: payload,
                has_managed_voice_search: payload.hasManagedVoiceSearch,
            });
        },
        saveVoiceSearch(payload = {
            siteTrackingCode: undefined,
        }) {
            return axiosInstance.put(`/sites/${payload.siteTrackingCode}/setup/voice_search`, {
                version: payload.version,
                config: payload.config,
                create_edge_url: payload.createEdgeUrl,
                create_preview_url: payload.createPreviewUrl,
                publish: payload.publish,
                modified: payload.modified,
                title: payload.title,
                message: payload.message,
            });
        },
    },
    campaigns: {
        createCampaign(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/campaigns/new`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        loadCampaigns(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/campaigns`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        deleteCampaign(payload = { siteId: undefined, campaign: undefined }) {
            return axiosInstance.delete(`/sites/${payload.siteId}/campaigns`, {
                data: {
                    campaign: payload.campaign,
                },
            });
        },
        save(payload = { siteId: undefined, campaign: undefined }) {
            return axiosInstance.post(`/sites/${payload.siteId}/campaigns`, {
                campaign: {
                    queries: payload.campaign.queries,
                    target_url: payload.campaign.targetUrl,
                    id: payload.campaign.id ? payload.campaign.id : null,
                    banners: {
                        autocomplete_list: {
                            desktop_url: payload.campaign.banners.autocompleteList.desktopUrl,
                            mobile_url: payload.campaign.banners.autocompleteList.mobileUrl,
                        },
                        autocomplete_top: {
                            desktop_url: payload.campaign.banners.autocompleteTop.desktopUrl,
                            mobile_url: payload.campaign.banners.autocompleteTop.mobileUrl,
                        },
                        search_header: {
                            desktop_url: payload.campaign.banners.searchHeader.desktopUrl,
                            mobile_url: payload.campaign.banners.searchHeader.mobileUrl,
                        },
                        search_panel: {
                            desktop_url: payload.campaign.banners.searchPanel.desktopUrl,
                            mobile_url: payload.campaign.banners.searchPanel.mobileUrl,
                        },
                        search_list: {
                            desktop_url: payload.campaign.banners.searchList.desktopUrl,
                            mobile_url: payload.campaign.banners.searchList.mobileUrl,
                        },
                        search_footer: {
                            desktop_url: payload.campaign.banners.searchFooter.desktopUrl,
                            mobile_url: payload.campaign.banners.searchFooter.mobileUrl,
                        },
                    },
                },
            });
        },
    },
    emergencyDeactivation: {
        toggle(payload = { siteId: undefined, service: undefined }) {
            return axiosInstance.post(`/sites/${payload.siteId}/settings/services/toggle`, {
                service: payload.service,
            });
        },
        checkStatus(payload = { url: undefined }) {
            return axiosInstance.get(payload.url, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        load(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/settings/services/show_json`);
        },
    },
    feedsPlan: {
        saveData(payload = { siteId: undefined, schedule: undefined }) {
            return axiosInstance.put(`/sites/${payload.siteId}/settings/feeds/plans`, {
                schedule: payload.schedule,
            });
        },
        runFeed(payload = { siteId: undefined, manuallyRunAt: undefined }) {
            return axiosInstance.post(`/sites/${payload.siteId}/settings/feeds/plans/run`, {
                manually_run_at: payload.manuallyRunAt,
            });
        },
    },
    feedsHistory: {
        loadHistory(payload = {
            siteId: undefined,
            sortBy: undefined,
            sortDirection: undefined,
            offset: undefined,
        }) {
            return axiosInstance.get(`/sites/${payload.siteId}/settings/feeds/history`, {
                headers: {
                    Accept: 'application/json',
                },
                params: {
                    sort_by: payload.sortBy,
                    sort_direction: payload.sortDirection,
                    offset: payload.offset,
                },
            });
        },
    },
    analyticsSessions: {
        loadSessions(payload = { siteId: undefined, userId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/settings/explore/events`, {
                headers: {
                    Accept: 'application/json',
                },
                params: {
                    user_id: payload.userId,
                },
            });
        },
        loadEvents(payload = { siteId: undefined, sessionId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/settings/explore/events/${payload.sessionId}/raws`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        loadEventDetail(payload = { siteId: undefined, sessionId: undefined, eventId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/settings/explore/events/${payload.sessionId}/raws/${payload.eventId}`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
    },
    dataQuality: {
        run(payload = { siteId: undefined }) {
            return axiosInstance.post(`/sites/${payload.siteId}/data_quality`);
        },
        stats(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/data_quality/stats`);
        },
        fetchMissingItems(payload = {
            siteId: undefined,
            limit: 10,
            offset: 0,
            verifiedOnly: true,
            sort_by: 'last_reported_at',
            sort_direction: 'desc',
        }) {
            return axiosInstance.get(`/sites/${payload.siteId}/data_quality/missing_items`, {
                params: {
                    limit: payload.limit,
                    offset: payload.offset,
                    verified_only: payload.verifiedOnly,
                    sort_by: payload.sort_by,
                    sort_direction: payload.sort_direction,
                },
            });
        },
        clearMissingItems(payload = { siteId: undefined }) {
            return axiosInstance.post(`/sites/${payload.siteId}/data_quality/clear_missing_items`);
        },
    },

    setupRecommenders: {
        search(payload = { siteId: undefined, query: undefined }) {
            return axiosInstance.get(`https://live.luigisbox.com/search?tracker_id=${payload.siteId}&f[]=type:category&q=${payload.query}`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        load(payload = { siteId: undefined }) {
            return axiosInstance.get(`/sites/${payload.siteId}/setup/recommender_rules`, {
                headers: {
                    Accept: 'application/json',
                },
            });
        },
        save(payload = { siteId: undefined, rule: undefined }) {
            return axiosInstance.post(`/sites/${payload.siteId}/setup/recommender_rules/save_rule`, {
                rule: {
                    condition_attribute: payload.rule.conditionAttribute,
                    filter_attribute: payload.rule.filterAttribute,
                    condition_values: payload.rule.conditionValues,
                    filter_values: payload.rule.filterValues,
                    recommender_id: payload.rule.recommenderId,
                    interaction: payload.rule.interaction,
                    rule_id: payload.rule.ruleId,
                    overrides: payload.rule.overrides,
                },
            });
        },
        delete(payload = { siteId: undefined, rule: undefined }) {
            return axiosInstance.delete(`/sites/${payload.siteId}/setup/recommender_rules`, {
                data: {
                    rule: {
                        condition_attribute: payload.rule.conditionAttribute,
                        filter_attribute: payload.rule.filterAttribute,
                        condition_values: payload.rule.conditionValues,
                        filter_values: payload.rule.filterValues,
                        recommender_id: payload.rule.recommenderId,
                        interaction: payload.rule.interaction,
                        rule_id: payload.rule.ruleId,
                    },
                },
            });
        },
    },
    integrationScreenshots(payload = {
        types: [],
        devices: ['desktop', 'android', 'iphone'],
        url: '',
        searchTerm: '',
        inputSelector: '',
        beforeStart: '',
        key: '',
    }) {
        return axiosInstance.post('/api/setup_screenshots/screenshots', {
            settings: payload,
        }, {});
    },
};
