import Vue from 'vue/dist/vue.esm';
import VueI18n from 'vue-i18n';
import VueLuxon from 'vue-luxon';
import Vuelidate from 'vuelidate';
import VueClipboard from 'vue-clipboard2';
import VueTippy, { TippyComponent } from 'vue-tippy';
import VueSlideToggle from 'vue-slide-toggle';

import $api from '../vue-utils/api';
import vueLangEn from '../../../config/locales/vue.en.yml';
import vueLangSk from '../../../config/locales/vue.sk.yml';

const clonedeep = require('lodash.clonedeep');

const locale = document.querySelector('meta[name="i18n-locale"]').getAttribute('content');

// Plugins
Vue.use(VueSlideToggle);
Vue.use(Vuelidate);
Vue.use(VueI18n);
Vue.use(VueClipboard);
Vue.use(VueLuxon, {
    output: {
        // locale: locale,
        format: 'dd.MM.yyyy',
    },
});
Vue.use(VueTippy, {
    theme: 'vue-tippy',
    arrow: false,
});
Vue.component('Tippy', TippyComponent);

// Filters

Vue.filter('englishNumeral', n => {
    let number = parseInt(n, 10);
    if (!Number.isNaN(number)) {
        if (number > 10 && number < 14) return `${number}th`;
        const nString = `${number}`;
        if (nString.length > 2) {
            number = parseInt(nString.substring(nString.length - 2), 10);
            if (number > 10 && number < 14) return `${number}th`;
        }
        number += '';
        number = number.split('').pop();
        if (number === '1') return `${n}st`;
        if (number === '2') return `${n}nd`;
        if (number === '3') return `${n}rd`;
    }
    return `${n}th`;
});

Vue.filter('striphtml', value => {
    const doc = new DOMParser().parseFromString(value, 'text/html');
    return doc.body.textContent || '';
});

// Prototypes
Vue.prototype.$window = window;
Vue.prototype.$api = $api;
Vue.prototype.$cd = clonedeep;

Vue.prototype.$notification = (text, typeParam = 'good', errorAction = null, autoHideAfter = 10000) => {
    let type = typeParam;

    if (typeParam === 'good') type = window.App.notification.states.stateGood;
    if (typeParam === 'bad') type = window.App.notification.states.stateBad;
    if (typeParam === 'loading') type = window.App.notification.states.stateLoading;
    if (typeParam === 'warning') type = window.App.notification.states.stateWarning;

    window.App.notification.show(type, text, errorAction, autoHideAfter);
};
Vue.prototype.$notificationHide = () => {
    window.App.notification.hide();
};

// Modules
Vue.component('QcQueries', () => import('../vue-components/query-customization/QcQueries.vue'));
Vue.component('QcDetail', () => import('../vue-components/query-customization/QcDetail.vue'));
Vue.component('QcTopItems', () => import('../vue-components/query-customization/QcTopItems.vue'));

Vue.component('ClCustomizations', () => import('../vue-components/category-listing/ClCustomizations.vue'));

Vue.component('EmergencyDeactivation', () => import('../vue-components/emergency-deactivation/EmergencyDeactivation.vue'));

Vue.component('DataQuality', () => import('../vue-components/data-quality/DataQuality.vue'));

Vue.component('AnalyticsSessions', () => import('../vue-components/analytics-sessions/AnalyticsSessions.vue'));

Vue.component('CsvBulkUpload', () => import('../vue-components/csv-bulk-upload/CsvBulkUpload.vue'));
Vue.component('VoiceSearch', () => import('../vue-components/setup/VoiceSearch.vue'));
Vue.component('SetupScreenshots', () => import('../vue-components/setup-screenshots/SetupScreenshots.vue'));

Vue.component('FeedsPlan', () => import('../vue-components/feeds-plan/FeedsPlan.vue'));

Vue.component('SearchPreview', () => import('../vue-components/search-preview/SearchPreview.vue'));

Vue.component('SetupRecommenders', () => import('../vue-components/setup-recommenders/SetupRecommenders.vue'));

Vue.component('FeedsHistory', () => import('../vue-components/feeds-history/FeedsHistory.vue'));

Vue.component('Campaigns', () => import('../vue-components/campaigns/Campaigns.vue'));
Vue.component('CampaignsDetail', () => import('../vue-components/campaigns/CampaignsDetail.vue'));

// Common stuff
Vue.component('Pagination', () => import('../vue-components/utils/Pagination.vue'));
Vue.component('ButtonDropdown', () => import('../vue-components/utils/ButtonDropdown.vue'));

// Init
const i18n = new VueI18n({
    locale,
    fallbackLocale: 'en',
    messages: {
        ...vueLangEn,
        ...vueLangSk,
    },
    pluralizationRules: {
        en: (inputChoice, choicesLength) => {
            // '1 apple | 2 apples | 0 apples'
            let ret = 1;
            const choice = Math.abs(inputChoice);

            if (choice === 1) {
                ret = 0;
            } else if (choice === 0) {
                ret = 2;
            }

            return ret < choicesLength ? ret : choicesLength - 1;
        },
        sk: (inputChoice, choicesLength) => {
            // '1 jablko | 2-4 jablka | 0;5+ jablk'
            // or
            // '1 jablko | 2-4 jablka | 5+ jablk | 0 jablk'
            let ret = 2;
            const choice = Math.abs(inputChoice);

            if (choice === 1) {
                ret = 0;
            } else if (choice > 1 && choice < 5) {
                ret = 1;
            } else if (choice === 0 && choicesLength === 4) {
                ret = 3;
            }

            return ret < choicesLength ? ret : choicesLength - 1;
        },
    },
});

Vue.mixin({
    mounted() {
        window.App.tippyInit();
    },
    updated() {
        window.App.tippyInit();
    },
});

window.Vuei18n = i18n;

window.Vue = Vue;
